import { utcToZonedTime, format } from 'date-fns-tz';

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Calendar.css';

function getPlayerColor(playerName) {
  switch (playerName) {
    case "이강인":
      return "#004170";
    case "김민재":
      return "#DC052D";
    case "손흥민":
      return "#132257";
    case "황희찬":
      return "#FDB913";
    case "이재성":
    return "#C3141E";
    case "정우영":
    return "#E32219";
    default:
      return "black"; // 기본 색상
  }
}

function Calendar({ playersData }) {
  const today = new Date();
  const weekDays = ['일', '월', '화', '수', '목', '금', '토'];

  const [allMatches, setAllMatches] = useState([]);

  useEffect(() => {
    const fetchAllMatches = async () => {
      const matchesWithPlayerNames = [];
  
      for (let player of playersData) {
        try {
          const response = await axios.get(`https://api.footcale.com/api/match-info/by-team-id/${player.teamId}`);
          
          const timeZone = 'Asia/Seoul';

          const matchesForPlayer = response.data.map(match => {
            const matchDate = new Date(match.matchDate);
            const kstDate = utcToZonedTime(matchDate, timeZone);
            return {
              // date: format(kstDate, 'yyyy-MM-dd HH:mm:ssXXX', { timeZone }), // 시간까지 표시하려면 이 형식 사용
              date: format(kstDate, 'yyyy-MM-dd', { timeZone }), // 일관된 날짜 포맷 사용
              playerName: player.name
            };
          });
          matchesWithPlayerNames.push(...matchesForPlayer);
        } catch (error) {
          console.error("Error fetching the matches for player:", player.name, error);
        }
      }
  
      setAllMatches(matchesWithPlayerNames);
    };
  
    fetchAllMatches();
  }, [playersData]);

  const getWeekDates = () => {
    const week = [];
    const timeZone = 'Asia/Seoul';
    for (let i = -1; i < 6; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      const formattedDate = format(date, 'yyyy-MM-dd', { timeZone: 'Asia/Seoul' });
      week.push(formattedDate);
    }
    return week;
  };

  const getPlayerNamesForDate = (formattedDate) => {
    const playerNamesOnDate = allMatches
      .filter(match => {
        const matchDate = format(utcToZonedTime(new Date(match.date), 'Asia/Seoul'), 'yyyy-MM-dd', { timeZone: 'Asia/Seoul' });
        return matchDate === formattedDate;
      })
      .map((match, index) => {
        const playerColor = getPlayerColor(match.playerName);
        return <div key={index} style={{ color: playerColor, fontWeight: 700 }}>{match.playerName}</div>;
      });
    return playerNamesOnDate;
  };

  return (
    <div className="calendar">
      {getWeekDates().map((formattedDate, index) => {
        const date = new Date(formattedDate);
        const isToday = format(today, 'yyyy-MM-dd') === formattedDate;
        const dayClass = `calendar-day ${isToday ? 'today' : ''}`;

        return (
          <div key={index} className={dayClass}>
            <span className="today-label">{isToday ? "오늘" : ""}</span>
            <span className="day-number">{date.getDate()}</span>
            <span> / </span>
            <span className="day-name">{weekDays[date.getDay()]}</span>
            <div className="player-names">{getPlayerNamesForDate(formattedDate)}</div>
          </div>
        );
      })}
    </div>
  );
}

export default Calendar;