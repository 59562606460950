import React, { useState, useEffect, useRef } from 'react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import io from 'socket.io-client';
import './Chat.css';



let socket;

function Chat() {
    const [message, setMessage] = useState('');
    const [chat, setChat] = useState([]);
    const [isFolded, setIsFolded] = useState(false);  // 채팅창의 접힘 상태를 관리하는 상태 변수

    // Fold, Unfold시 기억
    const chatBodyRef = useRef(null);

    // 현재 접속중인 참가자 수
    const [userCount, setUserCount] = useState(0); 

    const handleToggleFold = () => {
        setIsFolded(prev => !prev);
    };

    const [nickname, setNickname] = useState('');
    const getRandomNickname = () => {
        const nicknames = 
            [
                '호날두', '메시', '김민재', '황희찬', '손흥민',
                '이강인', '울브스', '핫스퍼', '토트넘', '뮌헨',
                '네이마르', '음바페', '비티냐', '마르퀴뇨스', '뎀벨레','아센시오','솔레르', '마티스텔','하키미','킴펨베', '루이스엔리케','뎀벨레',
                '다이어', '요리스', '메디슨', '비수마', '에메르송', 'BD33','사르','포스테코글루', '우도기', '스킵', '솔로몬','비카리오','드라구신','베르너',
                '해리케인', '키미히', '노이어', '알폰소', '키미히','더리흐트','우파메카노','사네','무시알라','코망','투헬'
            ];
        
        const randomIndex = Math.floor(Math.random() * nicknames.length);
        const randomNum = Math.floor(Math.random() * 999) + 1; // 1부터 999까지의 숫자
        

        return `${nicknames[randomIndex]}-${randomNum}`;
    };


    useEffect(() => {
        socket = io.connect('https://api.footcale.com');
        return () => {
            socket.disconnect();
        };

    }, []);

    useEffect(() => {
        socket.on('user count', (count) => {
          setUserCount(count + 150);  // 서버에서 전송한 사용자 수를 상태 변수에 저장합니다.
        });
    
        // 이 이펙트를 정리하는 데 사용되는 함수를 반환합니다.
        return () => {
          socket.off('user count');  // 'user count' 이벤트 핸들러를 제거합니다.
        };
      }, []);  // 빈 배열을 전달하여 이 이펙트를 컴포넌트가 마운트될 때만 실행하고, 업데이트될 때는 실행하지 않도록 합니다.
    

    useEffect(() => {
        setNickname(getRandomNickname());  // 컴포넌트가 마운트될 때 무작위 닉네임 설정
    }, []);

    useEffect(() => {
        socket.on('chat message', (msg) => {
            setChat((prevChat) => [...prevChat, msg]);
    
            setTimeout(() => {
                if (chatBodyRef.current) {
                    chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
                }
            }, 0);
        });
    }, []);

    useEffect(() => {
        if (!isFolded && chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [isFolded]);

    const onTextChange = (e) => {
        setMessage(e.target.value);
    };

    const onMessageSubmit = (e) => {
        e.preventDefault();
        const formattedMessage = message.replace(/(.{20})/g, "$1\n");
        socket.emit('chat message', { nickname, message: formattedMessage });
        setMessage('');
        
    };

    return (
        <div className={`chat-container ${isFolded ? 'folded' : ''}`}>
            <div className="chat-header" onClick={handleToggleFold}>
                <div className="chat-title">Chat ({userCount})</div>
                {isFolded ? <FaChevronUp className="chat-toggle-icon" /> : <FaChevronDown className="chat-toggle-icon" /> }
            </div>
             
            {!isFolded && (
                <>

                    <div className="chat-body" ref={chatBodyRef}>

                        {chat.map((msg, idx) => {
                            const isMine = msg.nickname === nickname;
                            return (
                                <div key={idx}>
                                    {msg.nickname === 'SYSTEM' ? 
                                        <span style={{color: 'red'}}>{msg.nickname}: {msg.message}</span> : 
                                        <>
                                            <span className={isMine ? "nickname-mine" : "nickname-others"}>{msg.nickname}:</span>
                                            <span> {msg.message}</span>
                                        </>
                                    }
                                </div>
                            );
                        })}
                    </div>

                    <form onSubmit={onMessageSubmit} className="chat-input-form">
                        <input
                            type="text"
                            value={message}
                            onChange={e => onTextChange(e)}
                            placeholder="축구 이야기를 나누어요"
                        />
                        <button type="submit">전송</button>
                    </form>
                </>
            )}
        </div>
    );
}

export default Chat;
