import React from 'react';
import './App.css';
import Player from './Player';
import Calendar from './Calendar';

import Chat from './Chat';

import son from './images/son.png';
import lee from './images/lee.png'; 
import kim from './images/kim.png'; 
import hhc from './images/hhc.png'; 

import ljs from './images/ljs.png'; 
import jwy from './images/jwy.png'; 



import logo from './images/full_logo.png';
import a120 from './images/120x600.png'
import a970 from './images/970x90.png'

const playersData = [
  {
    name: "손흥민",
    id: 212867,
    teamId: 8586,
    teamName: "토트넘 핫스퍼",
    teamColor: "#132257",
    image: son,
  },
  
  {
    name: "이강인",
    id: 940976,
    teamId: 9847,
    teamName: "파리 생제르맹",
    teamColor: "#004170",
    image: lee,
  },
  
  {
    name: "김민재",
    id: 828159,
    teamId: 9823,
    teamName: "바이에른 뮌헨",
    teamColor: "#DC052D",
    image: kim,
  },
  {
    name: "황희찬",
    id: 620026,
    teamId: 8602,
    teamName: "울버햄튼 원더러스",
    teamColor: "#FDB913",
    image: hhc,
  },
  {
    name: "이재성",
    id: 523749,
    teamId: 9905,
    teamName: "마인츠",
    teamColor: "#C3141E",
    image: ljs,
  },
  {
    name: "정우영",
    id: 949673,
    teamId: 10269,
    teamName: "슈투트가르트",
    teamColor: "#E32219",
    image: jwy,
  },


  // ... 다른 선수들의 정보 ...
];

function App() {
  
  return (
    <div className="App">

      <Chat />

      <header className="App-header">
        <div className="logo-container">
          <a href="https://www.footcale.com" target="_blank" rel="noopener noreferrer">
            <img src={logo} alt="Logo" className="logo-image"/>
          </a>
        </div>
      </header>
      <main className="App-main">
        <div className="main-container">
          <div className="a-panel"> 
            <img src={a120} alt="a120" className="a120"/> 
          </div>

          <div className="head"> <Calendar playersData={playersData} /> {/* 미니 달력 표시 */} </div>

          <div className="main-content">
            {playersData.map(player => (
              <Player 
                key={player.teamId}
                player={player}
              />
            ))}
          </div>
          
          <div className="a-panel2">  <img src={a120} alt="a120" className="a120"/>   </div>
          <div className="horizontal-a-panel"> <img src={a970} alt="a970" className="a970"/> </div>
        </div>
      </main>
    </div>
  );
}

export default App;