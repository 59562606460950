import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { format, utcToZonedTime } from 'date-fns-tz';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import bgPremier from './images/bg_premier.png';
import bgBundesliga from './images/bg_bundesliga.png';
import bgLeague1 from './images/bg_league1.png';
import bgChampions from './images/bg_champions.png';

import bgCopadefrancia from './images/bg_copadefrancia.png';
import bgFacup from './images/bg_facup.png';
import bgEFLcup from './images/bg_eflcup.png';
import bgPokalcup from './images/bg_pokalcup.png';

import './Player.css';



const getBackgroundStyle = (leagueName) => {
    let backgroundImage = '';
    switch (leagueName) {
      case 'Premier League':
        backgroundImage = `url(${bgPremier})`;
        break;
      case 'Bundesliga':
        backgroundImage = `url(${bgBundesliga})`;
        break;
      case 'Ligue 1':
        backgroundImage = `url(${bgLeague1})`;
        break;
      case 'Champions League':
        backgroundImage = `url(${bgChampions})`;
        break;
      case 'Coupe de France':
        backgroundImage = `url(${bgCopadefrancia})`;
        break;
      case 'FA Cup':
        backgroundImage = `url(${bgFacup})`;
        break;
      case 'EFL Cup':
        backgroundImage = `url(${bgEFLcup})`;
        break;
      case 'DFB Pokal':
        backgroundImage = `url(${bgPokalcup})`;
        break;
    


       
      default:
        backgroundImage = 'none';
        break;
    }
    return {
      backgroundImage: backgroundImage,
      backgroundColor: 'rgba(255, 255, 255, 0.85)', // 흰색 배경에 70% 투명도
      backgroundBlendMode: 'overlay' // 배경 이미지와 배경색을 혼합
    };
  };

const timeZone = 'Asia/Seoul'

function formatDate(dateString) {
    const date = new Date(dateString);
    const kstDate = utcToZonedTime(date, timeZone);
    return format(kstDate, 'M월 d일', { timeZone });
  }
  
  function formatTime(dateString) {
    if (!dateString) return 'Invalid date'; // 유효하지 않은 입력값 처리
  
    try {
      const date = new Date(dateString);
      const kstDate = utcToZonedTime(date, timeZone);
      const formattedTime = format(kstDate, 'p', { timeZone }); // 'p'는 date-fns 형식에서 시간을 나타냅니다.
      return formattedTime;
    } catch (error) {
      console.error('Error formatting time:', error);
      return 'Error formatting time'; // 오류 발생 시 처리
    }
  }

  function getYoutubeThumbnail(url) {
    if (!url) return '';  // url이 undefined 또는 null이면 빈 문자열을 반환합니다.
    const videoId = url.split('v=')[1]?.split('&')[0];
    return videoId ? `https://img.youtube.com/vi/${videoId}/0.jpg` : '';  // videoId가 없으면 빈 문자열을 반환합니다.
  }

  function getKoreanLeagueName(englishLeagueName) {
    switch (englishLeagueName) {
      case "Premier League":
        return "프리미어리그";
      case "Champions League":
        return "챔피언스리그";
      case "Ligue 1":
        return "리그1";
      case "Bundesliga":
        return "분데스리가";
      case "DFB Pokal":
        return "포칼컵";
      case "EFL Cup":
        return "EFL컵";
        case "LaLiga":
          return "라리가";
      case "Serie A":
          return "세리에 A";
      case "Europa League":
          return "유로파리그";
      case "Europa Conference League":
          return "유로파 컨퍼런스리그";
      case "Copa del Rey":
          return "코파 델레이";
      case "Coupe de France":
          return "쿠프 드 프랑스";
      case "Coppa Italia":
          return "코파 이탈리아";
      case "FA Cup":
          return "FA컵";
      case "Club Friendlies":
        return "친선경기";    
      default:
        return englishLeagueName;  // 일치하는 경우가 없으면 원래 이름을 반환
    }
  }

function Player({ player }) {
  const BACKEND_ENDPOINT = `https://api.footcale.com/api/match-info/by-team-id/${player.teamId}`;

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const [matches, setMatches] = useState([]);
  const [initialSlide, setInitialSlide] = useState(0);
  const [selectedMatch, setSelectedMatch] = useState(null);
  const [swiperInstance, setSwiperInstance] = useState(null); 
  const [showDetails, setShowDetails] = useState({});

  const [playerDetails, setPlayerDetails] = useState({
    rating: "N/A",
    playTime: "N/A",
    goals: "N/A",
    assists: "N/A",
    shots: "N/A",
    highlightsUrl: "#"
  });

  //모바일 감지, swiper 개수 변화
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [slidesPerView, setSlidesPerView] = useState(3); // 기본값은 3으로 설정



  const handleMatchClick = (match, index) => {
    if (selectedMatch === match && showDetails[match.matchId]) {
        // If the same match is clicked and details are already showing, toggle the details
        handleToggleDetails(match.matchId);
    } else {
        // Otherwise, proceed as usual
        setSelectedMatch(match);
        setShowDetails(prevState => ({
          ...prevState,
          [match.matchId]: true
        }));

      if (swiperInstance) {
          swiperInstance.slideTo(index, 500);
      }
    }
  };


  const handleToggleDetails = (matchId) => {
    setShowDetails(prevState => ({
      ...prevState,
      [matchId]: !prevState[matchId]
    }));
  };



  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;

    setSelectedMatch(matches[activeIndex]);
    if (selectedMatch) {  // selectedMatch가 null이 아닌지 확인
      setShowDetails(prevState => ({
        ...prevState,
        [matches[activeIndex].matchId]: prevState[selectedMatch.matchId]
      }));
    }
  };

  const fetchPlayerDetails = async (matchId) => {
    try {
      // 선수 정보와 하이라이트 정보를 동시에 요청
      const playerInfoPromise = axios.get(`https://api.footcale.com/api/match-player-info/${matchId}/${player.id}`);
      const highlightPromise = axios.get(`https://api.footcale.com/api/match-info/${matchId}/highlights`);
  
      // 두 요청의 결과를 기다림
      const [playerInfoResponse, highlightResponse] = await Promise.all([playerInfoPromise, highlightPromise]);
  
      // 선수 정보와 하이라이트 URL을 하나의 객체로 합쳐 상태 업데이트
      const updatedPlayerDetails = {
        ...playerInfoResponse.data, // 선수 정보 복사 (rating, playTime, goals, assists, shots)
        highlightsUrl: highlightResponse.data.highlightsUrl // 하이라이트 URL 추가
      };
  
      setPlayerDetails(updatedPlayerDetails);
    } catch (error) {
      console.error("Error fetching player details or highlights:", error);
      // 요청 실패 시 기본값 설정
      setPlayerDetails({
        rating: "TBA",
        playTime: "TBA",
        goals: "TBA",
        assists: "TBA",
        shots: "TBA",
        highlightsUrl: "#" // 기본 하이라이트 URL
      });
    }
  };
  

  

  useEffect(() => {
    const fetchMatches = async () => {

      try {
        const response = await axios.get(BACKEND_ENDPOINT);
        setMatches(response.data);
      
        let closestMatchIndex = 0;
        for (let i = 0; i < response.data.length; i++) {
          const matchDate = new Date(response.data[i].matchDate);
          if (matchDate >= today) {
            closestMatchIndex = i;
            break;
          }
        }

        setInitialSlide(closestMatchIndex);
        setMatches(response.data);
      } catch (error) {
        console.error("Error fetching the matches:", error);
      }
    };

    fetchMatches();
  }, []);



  useEffect(() => {
    if (selectedMatch) {

      const timeZone = 'Asia/Seoul';
      const matchDateUTC = new Date(selectedMatch.matchDate);
      const matchDateKST = utcToZonedTime(matchDateUTC, timeZone); // UTC를 KST로 변환
      const now = new Date();
  
      // 미래의 경기일 경우 highlightsUrl을 초기화하고 선수 정보 요청을 보내지 않음
      if (matchDateKST > now) {
        setPlayerDetails(prevDetails => ({
          ...prevDetails,
          highlightsUrl: "#" // 하이라이트 URL 초기화
        }));

        setPlayerDetails({
          rating: "N/A",
          playTime: "N/A",
          goals: "N/A",
          assists: "N/A",
          shots: "N/A",
          highlightsUrl: "#"
        });

        
      } else {
        // 과거 또는 현재의 경기일 경우 선수 정보 요청
        fetchPlayerDetails(selectedMatch.matchId);
      }
    }
  }, [selectedMatch]);  // selectedMatch의 변경에만 반응하도록 설정




  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // 화면 너비에 따라 slidesPerView 값을 조정
    if (windowWidth <= 932) { // 844px 이하에서는 slidesPerView를 1로 설정
      setSlidesPerView(1);
    } else {
      setSlidesPerView(3); // 그 외의 경우는 기본값인 3으로 설정
    }

    return () => window.removeEventListener('resize', handleResize);
  }, [windowWidth]); // windowWidth가 변경될 때마다 실행

  return (
    <div className="player-grid">
      <div className="player-section">
      <img src={player.image} alt={player.name} className="player-image" />
        
       <div className="player-name" style={{ fontWeight: 800 }} >{player.name}</div>
      </div>
      <div className="team-name" style={{ color: player.teamColor, fontWeight: 800 }}>{player.teamName} </div>
      <div className="match-info">
        {matches.length > 0 && (
          <Swiper
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={50}
            slidesPerView={slidesPerView}
            navigation
            initialSlide={initialSlide}
            centeredSlides={true}
            onSwiper={(swiper) => setSwiperInstance(swiper)}
            onSlideChange={(swiper) => handleSlideChange(swiper)}
          >
            {matches.map((match, index) => {
              const logoPath = `https://images.fotmob.com/image_resources/logo/teamlogo/${match.oppId}.png`;
              const matchDate = new Date(match.matchDate);
              const isFutureMatch = matchDate >= today;
              const isWin = match.myScore > match.oppScore;
              const isLose = match.myScore < match.oppScore;
              const isDraw = match.myScore === match.oppScore;

              const now = new Date();
              const timeDifference = matchDate.getTime() - now.getTime();
              const hoursUntilMatch = Math.floor(timeDifference / (1000 * 60 * 60));

              return (
                <SwiperSlide key={index} onClick={() => handleMatchClick(match, index)}> 
                  <div className="match-details">
                    <img src={logoPath} alt={`${match.oppName} logo`} className="team-logo" />
                    <div className="match-with">vs <b> {match.oppName} </b></div>
                    <div className="match-date">{formatDate(match.matchDate)} / {formatTime(match.matchDate)}</div>

                    {
                      match.matchStatus === 'FINISHED' ? (
                        <div className="match-score">
                          {match.myScore} - {match.oppScore} 
                          {isWin && <span style={{color: 'red', marginLeft: '10px', fontSize: '10px'}}>WIN</span>}
                          {isLose && <span style={{color: 'blue', marginLeft: '10px', fontSize: '10px'}}>LOSE</span>}
                          {isDraw && <span style={{color: 'green', marginLeft: '10px', fontSize: '10px'}}>DRAW</span>}
                        </div>
                      ) : match.matchStatus === 'IN_GAME' ? (
                        <div className="match-status" style={{ color: 'red', fontSize: '13px' }}> 
                          <span style={{ color: 'black', fontSize: '13px' }}> {match.myScore} - {match.oppScore}  </span>
                          <b> (진행중)</b>
                        </div> 
                      ) : match.matchStatus === 'NOT_STARTED' ? (

                          timeDifference > 0 && timeDifference < 24 * 60 * 60 * 1000 ? (
                            <div className="match-status" style={{ color: 'black', fontSize: '13px' }}>예정 ({hoursUntilMatch}시간 뒤)</div>
                          ) : (
                            <div className="match-status" style={{ color: 'black', fontSize: '13px' }}>예정</div>
                          )
                     
                        ) : match.matchStatus === 'CANCELLED' ? (
                        <div className="match-status"style={{ color: 'gray', fontSize: '13px' }}>취소</div>
                      ) : <div> </div>
                    }
                  </div>
                </SwiperSlide>
              );



            })}
          </Swiper>
        )}
      </div>

      {selectedMatch && showDetails[selectedMatch.matchId] && playerDetails && (
          <div
              className={`match-detail-panel ${showDetails[selectedMatch.matchId] ? 'match-detail-panel-open' : 'match-detail-panel-closed'}`}
              style={getBackgroundStyle(selectedMatch.leagueName)}
          >

          {windowWidth <= 932 ? (
            // Mobile view: Swiper for Match Details
            <>
                <Swiper
                  className ="mobile-swiper"
                  modules={[Navigation, Pagination]}
                  spaceBetween={10}
                  slidesPerView={1}
                  initialSlide={1}
                  navigation
                >
                <SwiperSlide>
                  <div className="mobile-left-panel">
                    <div className="mobile-left-header">선수 스텟</div>
                    <table>
                        <tbody>
                            <tr>
                                <th>평점</th>
                                <td>{playerDetails.rating ? playerDetails.rating : "0"}</td>
                            </tr>
                            <tr>
                                <th>출전시간</th>
                                <td>{playerDetails.playTime ? playerDetails.playTime : "0"}</td>
                            </tr>
                            <tr>
                                <th>득점</th>
                                <td>{playerDetails.goals ? playerDetails.goals : "0"}</td>
                            </tr>
                            <tr>
                                <th>도움</th>
                                <td>{playerDetails.assists ? playerDetails.assists : "0"}</td>
                            </tr>
                            <tr>
                                <th>슈팅</th>
                                <td>{playerDetails.shots ? playerDetails.shots : "0"}</td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="mobile-center-panel">
                    <div className="mobile-center-header">경기 정보</div>
          

                    <table>
                        <tbody>
                            <tr>
                                <th>경기날짜</th>
                                <td>{formatDate(selectedMatch.matchDate)}</td>
                            </tr>
                            <tr>
                                <th>경기시간</th>
                                <td>{formatTime(selectedMatch.matchDate)}</td>
                            </tr>
                            <tr>
                              <th>대회/리그</th>
                              <td>{getKoreanLeagueName(selectedMatch.leagueName)}</td>
                            </tr>
                                <th> 중계 </th>
                                <td>
                                  
                                    {selectedMatch.broadcast.url ?
                                      <b><a href={selectedMatch.broadcast.url} target="_blank" rel="noopener noreferrer">{selectedMatch.broadcast.name}</a></b>
                                      :
                                      selectedMatch.broadcast.name
                                    }
                                  
                                </td>

                            <tr>
                              <th>점수</th>
                              <td>
                                  {selectedMatch.matchStatus === "FINISHED" || selectedMatch.matchStatus === "IN_GAME" ?
                                      `${selectedMatch.myScore} - ${selectedMatch.oppScore}`
                                      : selectedMatch.matchStatus === "NOT_STARTED" ?
                                          "예정"
                                          : selectedMatch.matchStatus === "CANCELLED" ?
                                              "취소"
                                              : ""}
                              </td>
                            </tr>
                            <tr>
                                <th>홈/원정</th>
                                <td>{selectedMatch.isHome ? "홈" : "원정"}</td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="mobile-right-panel">
                    <div className="mobile-right-header">관련 영상</div>
                    {playerDetails.highlightsUrl && playerDetails.highlightsUrl !== '#' ? (
                        <a href={playerDetails.highlightsUrl} target="_blank" rel="noopener noreferrer">
                            <img 
                                src={getYoutubeThumbnail(playerDetails.highlightsUrl)} 
                                alt="Video Thumbnail" 
                                className="thumbnail-image" 
                            />
                            <div> 하이라이트 </div>
                        </a>
                    ) : (
                      <div style={{ marginTop: '50px', fontSize: 'small' }}>경기가 끝난 후 관련 영상이 나옵니다</div>
                    )}
                  </div>
                </SwiperSlide>
              </Swiper>
            </>
            ) : (
              <>
                <div className="detail-header">
                  <div className="panel-headers">
                    <div className="left-header">선수 스텟</div>
                    <div className="center-header">경기 정보</div>
                    <div className="right-header">관련 영상</div>
                  </div>

                  <div className="arrow-up" onClick={() => handleToggleDetails(selectedMatch.matchId)}></div>
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="arrow-icon"
                        onClick={() => handleToggleDetails(selectedMatch.matchId)}
                    >
                        <path d="M6 15L12 9L18 15" stroke="black" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </div>

                 <div className="panels-container">
                    <div className="left-panel">
                      <table>
                          <tbody>
                              <tr>
                                  <th>평점</th>
                                  <td>{playerDetails.rating ? playerDetails.rating : "0"}</td>
                              </tr>
                              <tr>
                                  <th>출전시간</th>
                                  <td>{playerDetails.playTime ? playerDetails.playTime : "0"}</td>
                              </tr>
                              <tr>
                                  <th>득점</th>
                                  <td>{playerDetails.goals ? playerDetails.goals : "0"}</td>
                              </tr>
                              <tr>
                                  <th>도움</th>
                                  <td>{playerDetails.assists ? playerDetails.assists : "0"}</td>
                              </tr>
                              <tr>
                                  <th>슈팅</th>
                                  <td>{playerDetails.shots ? playerDetails.shots : "0"}</td>
                              </tr>
                          </tbody>
                      </table>
                    </div>

                    <div className="center-panel">
                      <table>
                          <tbody>
                              <tr>
                                  <th>경기날짜</th>
                                  <td>{formatDate(selectedMatch.matchDate)}</td>
                              </tr>
                              <tr>
                                  <th>경기시간</th>
                                  <td>{formatTime(selectedMatch.matchDate)}</td>
                              </tr>
                              <tr>
                                <th>대회/리그</th>
                                <td>{getKoreanLeagueName(selectedMatch.leagueName)}</td>
                                </tr>
                              <tr>
                                  <th> 중계 </th>
                                  <td>
                                      {selectedMatch.broadcast.url ?
                                        <b><a href={selectedMatch.broadcast.url} target="_blank" rel="noopener noreferrer">{selectedMatch.broadcast.name}</a></b>
                                        :
                                        selectedMatch.broadcast.name
                                      }
                                    
                                  </td>
                              </tr>          
                              <tr>
                                <th>점수</th>
                                <td>
                                    {selectedMatch.matchStatus === "FINISHED" || selectedMatch.matchStatus === "IN_GAME" ?
                                        `${selectedMatch.myScore} - ${selectedMatch.oppScore}`
                                        : selectedMatch.matchStatus === "NOT_STARTED" ?
                                            "예정"
                                            : selectedMatch.matchStatus === "CANCELLED" ?
                                                "취소"
                                                : ""}
                                </td>
                              </tr>
                              <tr>
                                  <th>홈/원정</th>
                                  <td>{selectedMatch.isHome ? "홈" : "원정"}</td>
                              </tr>
                          </tbody>
                      </table>
                    </div>
                                      
                    <div className="right-panel">
                      {playerDetails.highlightsUrl && playerDetails.highlightsUrl !== '#' ? (
                          <a href={playerDetails.highlightsUrl} target="_blank" rel="noopener noreferrer">
                              <img 
                                  src={getYoutubeThumbnail(playerDetails.highlightsUrl)} 
                                  alt="Video Thumbnail" 
                                  className="thumbnail-image" 
                              />
                              <div> 하이라이트 </div>
                          </a>
                      ) : (
                          <div>경기가 끝난 후 관련 영상이 나옵니다</div>
                      )}
                    </div>
                  </div>
              </>  
              )}
          </div>
        )}
    </div>
  );
}

export default Player;